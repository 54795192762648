import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Image01 from '../../images/user-36-05.jpg';
import Image02 from '../../images/user-36-06.jpg';
import Image03 from '../../images/user-36-07.jpg';
import Image04 from '../../images/user-36-08.jpg';
import Image05 from '../../images/user-36-09.jpg';

function DashboardCard10() {

  const [certs, setCerts] = useState([]);
  const [recogs, setRecogs] = useState([]);
  const [seminars, setSeminars] = useState([]);
  const [scannedCerts, setScannedCerts] = useState([]);

  const getCerts = async () => {
    await axios.get('https://wadcas-be.herokuapp.com/certificate/diplomas').then((res) => {
      // console.log(res.data.docs);

      setCerts(res.data.docs);
      console.log(certs);
    });

  };

  const getRecogs = async () => {
    await axios.get('https://wadcas-be.herokuapp.com/certificate/recognitions').then((res) => {
      // console.log(res.data.docs);

      setRecogs(res.data.docs);
      console.log(certs);
    });

  };

  const getSeminars = async () => {
    await axios.get('https://wadcas-be.herokuapp.com/certificate/seminars').then((res) => {
      // console.log(res.data.docs);

      setSeminars(res.data.docs);
      console.log(certs);
    });

  };

  const getScanCerts = async () => {
    await axios.get('https://wadcas-be.herokuapp.com/certificate/scanned_docs').then((res) => {
      // console.log(res.data.docs);

      setScannedCerts(res.data);
      console.log(scannedCerts);
    });
  };

  const deleteCert = async (id) => {
    await axios.delete(`https://wadcas-be.herokuapp.com/certificate/${id}`);

    getCerts();
    getRecogs();
    getSeminars();
  };

  useEffect(() => {
    getRecogs();
    getSeminars();
    getCerts();
    getScanCerts();
  }, []);


  return (
    <>

    <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Diplomas</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Holder Full Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Program</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Document Type</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Action</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {
                certs.map(cert => {
                  return (
                    <tr key={cert._id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{cert.fullname}</div>
                    </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{cert.program}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500">{cert.document_type}</div>
                      </td>
                      
                      <td className="p-2 whitespace-nowrap">
                      <button onClick={() => {
                          deleteCert(cert._id);
                        }}
                       class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Delete
                      </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </div>

      </div>
    </div>

    <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Recognitions</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Holder Full Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Program</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Document Type</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Action</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {
                recogs.map(recog => {
                  return (
                    <tr key={recog._id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{recog.fullname}</div>
                    </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{recog.program}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500">{recog.document_type}</div>
                      </td>
                      
                      <td className="p-2 whitespace-nowrap">
                      <button onClick={() => {
                          deleteCert(recog._id);
                        }}
                       class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Delete
                      </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </div>

      </div>
    </div>

    <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Seminars</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Holder Full Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Program</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Document Type</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Action</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {
                seminars.map(seminar => {
                  return (
                    <tr key={seminar._id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{seminar.fullname}</div>
                    </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{seminar.program}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500">{seminar.document_type}</div>
                      </td>
                      
                      <td className="p-2 whitespace-nowrap">
                      <button onClick={() => {
                          deleteCert(seminar._id);
                        }}
                       class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Delete
                      </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </div>

      </div>
    </div>
    
    </>
    
  );
}

export default DashboardCard10;
