import React from 'react';
import WebViewerPDFTron from '../WebViewerPDFTron';


function DashboardCard07() {
  return (
      <WebViewerPDFTron />
  );
}

export default DashboardCard07;
